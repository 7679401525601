const faqs = [
  {
    question: "Visas De No Inmigrante (Temporales):",
    answer: (
      <div>
        <p>Se trata de visados temporales que se conceden a ciudadanos extranjeros que desean viajar a EE.UU. durante un periodo determinado. Hay más de 20 tipos de visados de no inmigrante, pero hay tres categorías principales: visados de visitante, visados de estudio y visados de empleo.</p>
      </div>
    ),
  },
  {
    question: "Visas De Visitante:",
    answer: (
      <div>
        <p>Los visados de visitante se expiden a ciudadanos extranjeros que desean entrar en Estados Unidos de forma temporal por tres motivos: turismo, negocios o una combinación de ambos. </p>
      </div>
    ),
  },
];

export default faqs;
